// plugins/websocket.js
export default function (Vue) {
    Vue.prototype.$tioWs = function (
        urlcreator,
        param,
        handler,
        heartbeatTimeout = 10000,
        reconnInterval = 10000,
        binaryType = 'blob'
    ) {
        const wsInstance = {
            binaryType: binaryType || 'arraybuffer',
            urlcreator: urlcreator,
            param: param,
            handler: handler,
            heartbeatTimeout: heartbeatTimeout,
            reconnInterval: reconnInterval,
            stopped: false,
            lastInteractionTimeValue: new Date().getTime(),

            lastInteractionTime(time) {
                if (arguments.length === 1) {
                    this.lastInteractionTimeValue = time;
                }
                return this.lastInteractionTimeValue;
            },

            heartbeatSendInterval: heartbeatTimeout / 2,

            async connect(isReconnect = false) {
                // 获取当前时间
                const now = new Date();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const currentTime = `${hours}:${minutes}:${seconds}`;

                // 输出 WebSocket 消息和当前时间
                console.log(`initWebSocket connectat ${currentTime}`)
                const self = this;
                try {
                    const imServerMeta = await this.urlcreator(isReconnect);
                    let _url = imServerMeta.url || imServerMeta;

                    if (imServerMeta.timeout) {
                        this.heartbeatTimeout = imServerMeta.timeout;
                        this.heartbeatSendInterval = this.heartbeatTimeout / 2;
                    }

                    const ws = new WebSocket(_url);
                    this.ws = ws;
                    ws.binaryType = this.binaryType;

                    ws.onopen = function (event) {
                        self.handler.onopen(event, ws);
                        self.lastInteractionTime(new Date().getTime());

                        self.pingIntervalId = setInterval(() => {
                            self.ping();
                        }, self.heartbeatSendInterval);
                    };

                    ws.onmessage = function (event) {
                        self.handler.onmessage(event, ws);
                        self.lastInteractionTime(new Date().getTime());
                    };

                    ws.onclose = function (event) {
                        clearInterval(self.pingIntervalId);
                        if (self.handler.onclose) {
                            self.handler.onclose(event, ws);
                        }
                        self.reconn(event);
                    };

                    ws.onerror = function (event) {
                        if (self.handler.onerror) {
                            self.handler.onerror(event, ws);
                        }
                    };

                    return ws;
                } catch (e) {
                    console.error('WebSocket connection error:', e);
                    setTimeout(() => this.connect(false), this.reconnInterval);
                }
            },

            reconn(event) {
                if (!this.stopped) {
                    setTimeout(() => {
                        if (!this.stopped) {
                            this.connect(true);
                        }
                    }, this.reconnInterval);
                }
            },

            ping() {
                console.log("websocket.js Sending Ping");
                if (!this.stopped) {
                    const iv = new Date().getTime() - this.lastInteractionTime();
                    if (this.heartbeatSendInterval + iv >= this.heartbeatTimeout) {
                        this.handler.ping(this.ws);
                    }
                }
            },

            send(data) {
                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    console.log("send发送消息"+data);
                    this.ws.send(data);
                }
            },

            stop() {
                this.stopped = true;
                if (this.ws) {
                    this.ws.close();
                }
            }
        };

        return wsInstance;
    };
}
